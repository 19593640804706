import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'
import { useAuth } from '../contexts/AuthContext'

function Articles() {

  const [loading, setLoading] = useState(true);
  const { currentUser, customClaimRole, articles } = useAuth();
  const [expandedPanel, setExpandedPanel] = useState(false);
  const navigate = useNavigate()

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleArticleChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    const anchor = window.location.hash.split('#')[1];
    if (anchor) {
      setExpandedPanel(anchor)
      window.scrollTo({
        top: anchor,
        behavior: "smooth"
      });
    }

    return () => {
      cancel = true;
    }
  }, []);

  if (loading === true) {

    return <LoaderScreen />

  } else {
    
    return (
      <>
        <Box mt={5}>
          {articles.map((ar, i) => {
            return (
              <React.Fragment key={i}>
                <Accordion id={"#" + ar.urlfrag} elevation={0} expanded={expandedPanel === ar.urlfrag} onChange={handleArticleChange(ar.urlfrag)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={ar.intro}
                    id={ar.title}
                  >
                    <Typography mt={2} mb={2} variant="h4" sx={{ fontSize: '150% !important', width: '80%', flexShrink: 0, alignItems: 'center', display: 'flex' }}>{ar.title}</Typography>
                    <Box mr={1} sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                      <img src={ar.picture} alt="$" height="50px" width="auto" display="inline" style={{ alignItems: 'right' }} />
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography mt={2} mb={2} variant="h5">{ar.subtitle}</Typography>
                    <Typography alight="right" style={{ whiteSpace: 'pre-line' }}><i>{ar.intro}</i></Typography>
                    {currentUser && (
                      <Grid>
                        {(customClaimRole === "user") ? (
                          <>
                            <Grid item xs={12} sm={12} md={6}>
                              <Box style={{ background: 'linear-gradient(to right bottom, #00, #ffffff' }}>
                                <Typography mt={5} mb={2} variant="h4" display="block" sx={{ fontSize: '135% !important' }} >{ar.title}</Typography>
                                <Typography mt={2} mb={5} variant="body1" display="block" style={{ whiteSpace: 'pre-line' }}>{(ar.body).slice(0, 199)} .....</Typography>
                              </Box>
                              <Button fullWidth size="md" sx={{ height: '50px', fontSize: '110%' }} variant="outlined" onClick={(e) => { e.preventDefault(); navigate("../") }}>Subscribe to See More</Button>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Typography mt={5} mb={2} variant="h4" display="block" sx={{ fontSize: '135% !important' }} >{ar.title}</Typography>
                            <Typography mt={2} mb={2} variant="body1" display="block" style={{ whiteSpace: 'pre-line' }}>{ar.body}</Typography>
                          </>
                        )}
                        {(customClaimRole === "employee" || customClaimRole === "admin") && (
                          <>
                            <Typography mt={3} mb={2} variant="h4" display="block" sx={{ fontSize: '125% !important' }} >for employees</Typography>
                            <Typography mt={2} mb={2} variant="body1" display="block" sx={{ fontSize: '110%' }} style={{ whiteSpace: 'pre-line' }}>{ar.employee}</Typography>
                          </>
                        )}
                        {(customClaimRole === "employer" || customClaimRole === "admin") && (
                          <>
                            <Typography mt={3} mb={2} variant="h4" display="block" sx={{ fontSize: '125% !important' }} >for employers</Typography>
                            <Typography mt={2} mb={2} variant="body1" display="block" sx={{ fontSize: '110%' }} style={{ whiteSpace: 'pre-line' }}>{ar.employer}</Typography>
                          </>
                        )}
                        {customClaimRole !== "user" && (
                          <>
                            <Typography mt={3} mb={2} variant="h4" display="block" sx={{ fontSize: '125% !important' }}>references</Typography>
                            {articles[i].links.map((li, idx) => {
                              return (
                                <ListItem key={idx} button component="a" onClick={() => openInNewTab(li.link)}>
                                  <ListItemText primary={li.text} fontSize="small" sx={{ display: 'list-item' }} />
                                </ListItem>
                              )
                            })}
                          </>
                        )}
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
                <br />
                <Divider />
              </React.Fragment>
            );
          })
          }
          <br />
        </Box>
      </>
    );
  }
}

export default Articles
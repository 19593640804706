import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import ListItemText from '@mui/material/ListItemText'
import { NumericFormat } from 'react-number-format'
import LoaderScreen from '../components/LoaderScreen'
import Typography from '@mui/material/Typography'
import { useAuth } from '../contexts/AuthContext'

function Products() {

  const { products, CheckoutSession } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    }
  }, []);

  if (loading === true) {

    return <LoaderScreen />

  } else {

    return (
      <Grid>
        {products.map((p, i) => (
          <Grid item mt={3} mb={3} xs={6} sm={6} md={6} key={i}>
            <Card mt={3} mb={3} sx={{ p: 1, pt: 1.5, pb: 1.5 }}>
              <CardContent>
                <img src={p.images[0]} alt="$" height="75" width="auto" margin-top="-5%" align="right" display="inline" style={{ border: '0.5px solid #C0C0C0', borderRadius: '5px', boxShadow: '1px 2px #C0C0C0' }} />
                <Typography align="left" variant="h4">MyGigCo <Box fontSize="110%" display="inline" sx={{ color: '#C0C0C0' }}>{p.role}s</Box></Typography>
                <Typography align="left" mb={1} sx={{ fontSize: 14 }} color="text.secondary">MyGigCo works for you. Just like you do.</Typography>
                <Typography mt={2} mb={1} align="left" variant="h2"><NumericFormat value={(p.unit_amount / 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /><Box fontSize="50%" display="inline"> per {p.interval}</Box></Typography>
                <Typography align="left" variant="body2"><i><b>1-{p.interval}</b> {p.description}</i></Typography>
                <Typography mt={2} variant="body2">
                  This is a <b>{p.interval === "week" && "weekly"}{p.interval === "month" && "monthly"}{p.interval === "year" && "annual"}</b> subscription can be cancelled or resumed at any time. To cancel, click the <b>Manage MyGigCo Subscription</b> button at the bottom of the profile page and you will be taken to the subscription page. You must cancel at least 48 hours before the end date and time of your subscription. is effective at the end of the current {p.interval} and there is no prorata refund.</Typography>
              </CardContent>
              <CardActions>
                <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <Button fullWidth size="lg" sx={{ height: '75px', fontSize: '110%' }} variant="outlined" onClick={() => CheckoutSession(p.priceId)}>Get {p.interval === "week" && "Weekly"}{p.interval === "month" && "Monthly"}{p.interval === "year" && "Annual"} {p.role} Access</Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default Products
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, responsiveFontSizes, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import GeomanistLight from './fonts/geomanist-light.woff2'
import GeomanistLightItalic from './fonts/geomanist-light-italic.woff2'
import GeomanistRegular from './fonts/geomanist-regular.woff2'
import GeomanistRegularItalic from './fonts/geomanist-regular-italic.woff2'
import GeomanistMedium from './fonts/geomanist-medium.woff2'
import GeomanistBold from './fonts/geomanist-bold.woff2'

const theme = responsiveFontSizes(createTheme({

  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Geomanist Regular, san-serif',
    h1: {
      fontFamily: 'Geomanist Bold, san-serif',
    },
    h2: {
      fontFamily: 'Geomanist Medium, san-serif',
    },
    h3: {
      fontFamily: 'Geomanist Medium, san-serif',
    },
    h4: {
      fontFamily: 'Geomanist Medium, san-serif',
    },
    h5: {
      fontFamily: 'Geomanist Regular, san-serif',
    },
    h6: {
      fontFamily: 'Geomanist Regular, san-serif',
    },
    subtitle1: {
      fontFamily: 'Geomanist Medium, san-serif',
    },
    subtitle2: {
      fontFamily: 'Geomanist Regular, san-serif',
    },
    body1: {
      fontFamily: 'Geomanist Regular, san-serif',
    },
    body2: {
      fontFamily: 'Geomanist Light, san-serif',
    },
    button: {
      textTransform: 'none',
      fontFamily: 'Geomanist Medium, san-serif',
    },
    caption: {
      fontFamily: 'Geomanist Regular Italic, san-serif',
    },
    overline: {
      fontFamily: 'Geomanist Light Italic, san-serif',
    },
    b: {
      fontFamily: 'Geomanist Medium, san-serif',
    }
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Geomanist Light';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Geomanist Light'), local('Geomanist-Light'), url(${GeomanistLight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Geomanist Light Italic';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Geomanist Light Italic'), local('Geomanist-Light-Italic'), url(${GeomanistLightItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Geomanist Regular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Geomanist Regular'), local('Geomanist-Regular'), url(${GeomanistRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Geomanist Regular Italic';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Geomanist Regular Italic'), local('Geomanist-Regular-Italic'), url(${GeomanistRegularItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Geomanist Medium';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Geomanist Medium'), local('Geomanist-Medium'), url(${GeomanistMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Geomanist Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Geomanist Bold'), local('Geomanist-Bold'), url(${GeomanistBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-outlined": {
            color: '#000000',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
              border: '1px solid rgba(0, 0, 0, 0.3)',
            },
          },
          "&.MuiButton-outlinedSuccess": {
            color: '#000000',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            backgroundColor: '#F6FFF6',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
              border: '1px solid rgba(0, 0, 0, 0.3)',
            },
          },
          "&.MuiButton-outlinedError": {
            color: '#000000',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            backgroundColor: '#FFF6F6',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
              border: '1px solid rgba(0, 0, 0, 0.3)',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: 'rgba(0, 0, 0, 0.8)',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '5px !important',
        }
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          opacity: '0.1',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Geomanist Regular, san-serif',
          fontSize: '0.75rem',
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "black",
        backgroundColor: "white",
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '5px'
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          alignItems: 'center',
          height: '85px',
          fontSize: '2.5rem',
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: '70px',
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          backgroundColor: '#000000',
          "&.Mui-selected": {
            backgroundColor: '#212121',
            color: '#9F9F9F',
          },
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: "100%"
        },
        accordion: {
          minHeight: 150,
          height: "100%"
        },
        details: {
          alignItems: "center",
          border: "1px solid rgba(0,0,0,0.1)",
          borderRadius: 4
        }
      }
    }
  },
}))

export default function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}

import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'
//import {  } from 'date-fns'
import { auth, db } from '../firebase'
import { signInWithPopup, linkWithPopup, linkWithCredential, unlink, EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updateEmail, updatePassword, deleteUser, onAuthStateChanged, signOut, setPersistence, browserLocalPersistence } from 'firebase/auth'
import { onSnapshot, doc, getDocs, getDoc, addDoc, setDoc, collection, query, limit, where } from 'firebase/firestore'

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [userData, setUserData] = useState([{
    email: ''
  }]);
  const [customClaimRole, setCustomClaimRole] = useState("")
  const [bottomNav, setBottomNav] = useState("")
  const [products, setProducts] = useState([])
  const [articles, setArticles] = useState([])
  const [subscriptionsData, setSubscriptionsData] = useState([])
  //const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const [currentLocation, setCurrentLocation] = useState()
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');
  const [loading, setLoading] = useState(true)

  const formatUser = async (user) => {
    const token = await user.getIdToken();
    return {
      uid: user.uid,
      providerData: user.providerData,
      firstName: '',
      lastName: '',
      address: '',
      token
    };
  };

  const formatCheckPay = async (user) => {
    return {
      no: 0
    };
  };

  async function handleUser(rawUser) {
    if (rawUser) {
      const user = await formatUser(rawUser);
      createUser(user.uid, user)
      setCurrentUser(user)

      return user
    } else {
      setCurrentUser(false)
      return false
    }
  }

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(response => {
        handleUser(response.user)
      })
  }

  async function createUser(uid, user) {
    const userRef = doc(db, "users", uid);
    await setDoc(userRef, user)
      .then(() => {
        console.log("Added Firestore entry for user: " + uid);
      })
      .catch(err => {
        console.error(err);
        alert(err.message);
      })
  }

  async function getUser(uid) {
    const userRef = doc(db, "users", uid);
    return await getDoc(userRef)
      .then(docSnap => {
        if (docSnap) {
          return docSnap.data();
        } else {
          return false
        }
      })
  }

  function updateUser(uid, updatedata) {
    const userRef = doc(db, "users", uid);
    return setDoc(userRef, {
      ...updatedata
    }, { merge: true });
  }

  function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
    });
  }

  async function getCoordinatesPlaceId(pid) {
    await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${pid}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`)
      .then(res => {
        const coords = res.data.results
        console.log(coords.map(c => {
          return c.geometry.location
        }))
      })
  }

  async function getArticles() {
    const articleRef = collection(db, "articles");
    const q = query(articleRef)
    return await getDocs(q)
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        return data;
      });
  }

  async function getProductsPrices() {
    let consolidated = []
    const prodRef = collection(db, "products");
    const q = query(prodRef, where("active", "==", true))
    return await getDocs(q)
      .then(function (querySnapshot) {
        querySnapshot.forEach(async function (prod) {
          const productData = prod.data()
          const pricesRef = collection(db, "products", prod.id, "prices");
          const priceSnap = await getDocs(pricesRef)
          priceSnap.docs.forEach(pric => {
            const priceId = pric.id
            const priceData = pric.data()
            console.log(pric.data())
            const consol = { priceId, ...priceData, ...productData }
            consolidated.push(consol)
          })
          setProducts(consolidated)
        });

      });
  }

  async function getSubscriptions(uid) {
    const subsRef = collection(db, "users", uid, "subscriptions");
    const q = query(subsRef)
    return await getDocs(q)
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        return data;
      });
  }

  async function checkSubcollection(uid, sc) {
    const scRef = collection(db, "users", uid, sc);
    const q = query(scRef, limit(1));
    const querySnapshot = await getDocs(q);
    const day1doc = querySnapshot.docs[0]
    const docRef = doc(db, "users", uid, sc, day1doc.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const day1doc = docSnap.data()
      if (day1doc.day === 0) {
        return true
      } else {
        return false
      }
    } else {
      alert("There may be a problem with the hours data for user " + uid);
    }
  }

  async function getSubColDocs(uid, sc) {
    const scRef = collection(db, "users", uid, sc);
    const q = query(scRef)
    return await getDocs(q)
      .then(querySnapshot => {
        const data = querySnapshot.docs.map(doc => doc.data());
        return data;
      });
  };

  async function signInWithGoogle() {
    return await signInWithPopup(auth, googleProvider)
      .then(response => {
        console.log(JSON.stringify(response))
        getUser(response.user.uid)
          .then(r => {
            if (!r) {
              handleUser(response.user)
            } else {
              return
            }
          })
      })
  }

  async function signInWithFacebook() {
    facebookProvider.addScope('email');
    return await signInWithPopup(auth, facebookProvider)
      .then(response => {
        console.log(JSON.stringify(response))
        getUser(response.user.uid)
          .then(r => {
            if (!r) {
              handleUser(response.user)
            } else {
              return
            }
          })
      })
  }

  async function signInWithMicrosoft() {
    return await signInWithPopup(auth, microsoftProvider)
      .then(response => {
        console.log(JSON.stringify(response))
        console.log(currentUser.email)
        getUser(response.user.uid)
          .then(r => {
            if (!r) {
              handleUser(response.user)
            } else {
              return
            }
          })
      })
  };

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  async function getCustomClaimRole() {
    const idToken = await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    const url = 'https://australia-southeast1-mygigco.cloudfunctions.net/isAdmin';

    const response = await fetch(url, {
      "method": "POST",
      "headers": { "Content-Type": "application/json" },
      "body": JSON.stringify({ "idToken": idToken })
    });

    if (response.ok) {
      const json = await response.json();
      console.log(JSON.stringify(json.isAdmin))

      const isAdmin = json.isAdmin;

      return isAdmin ? "admin" : decodedToken.claims.stripeRole;
    }
  }

  function linkEmailProvider(email, password) {
    const credential = EmailAuthProvider.credential(email, password)
    return linkWithCredential(auth.currentUser, credential).then(usercred => {
      const user = usercred.user;
      console.log("Link with Email successful", user);
    }).catch((err) => {
      console.log("This email could not be linked to your MyGigCo account.", err);
    });
  }

  function linkGoogleProvider() {
    const googleProvider = new GoogleAuthProvider();
    return linkWithPopup(auth.currentUser, googleProvider).then(result => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const user = result.user;
      console.log("Link with Goggle successful", user, credential);
    })
  }

  function linkFacebookProvider() {
    const facebookProvider = new FacebookAuthProvider();
    return linkWithPopup(auth.currentUser, facebookProvider).then(result => {
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const user = result.user;
      console.log("Link with Facebook successful", user, credential);
    })
  }

  function linkMicrosoftProvider() {
    const microsoftProvider = new OAuthProvider('microsoft.com');
    return linkWithPopup(auth.currentUser, microsoftProvider).then(result => {
      const credential = OAuthProvider.credentialFromResult(result);
      const user = result.user;
      console.log("Link with Microsoft successful", user, credential);
    })
  }

  async function updateAccountEmail(email) {
    return await updateEmail(currentUser, email);
  }

  async function updateAccountPassword(password) {
    return await updatePassword(currentUser, password);
  }

  function unlinkProvider(pid) {
    return unlink(auth.currentUser, pid)
  }

  /*   async function getCustomClaimRole() {
      await auth.currentUser.getIdToken(true);
      const decodedToken = await auth.currentUser.getIdTokenResult();
      console.log(JSON.stringify(decodedToken.claims))
      return decodedToken.claims.stripeRole;
    } */

  function deleteAccount() {
    const user = auth.currentUser;
    return deleteUser(user)
  }

  async function CheckoutSession(pr) {
    setLoading(true)
    const dbRef = collection(db, "users", currentUser.uid, "checkout_sessions");
    const docRef = await addDoc(dbRef, {
      price: pr,
      allow_promotion_codes: true,
      trial_from_plan: false,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });
    onSnapshot(docRef, (doc) => {
      console.log("Checkout session created with id: " + doc.id);
      const { error, url } = doc.data();
      if (error) {
        console.log("An error occured: " + error.message);
      }
      if (url) {
        window.location.assign(url);
      }
    });
  }

  async function logout() {
    try {
      await signOut(auth)
      console.log(currentUser.uid + " has now signed out of MyGigCo.")
      setCustomClaimRole(null)
    } catch (error) {
      console.log(error)
    }
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          setCurrentUser(user)
          await getUser(user.uid)
            .then(userdata => {
              setUserData(userdata)
            });
          await getCustomClaimRole()
            .then(res => {
              const role = res ? res : "user"
              console.log("AuthContext CCR set as: " + role)
              setCustomClaimRole(role)
            });
          await getProductsPrices()
            .then(prods => {
              setProducts(prods)
            });
          getCurrentLocation()
          console.log(currentLocation)
          getCoordinatesPlaceId("EikxNzAyLzQ2IFNhdm9uYSBEcml2ZSwgV2VudHdvcnRoIFBvaW50IE5TVyI6GjgKMBIuChQKEgl5B0YN7qQSaxHuEqzHs1ozdRAuKhQKEgnHxQ4-7KQSaxEvEYiW_CdGeRIEMTcwMg")
          await getArticles()
            .then(art => {
              setArticles(art);
            });
          await getSubscriptions(user.uid)
            .then(subs => {
              if (subs) {
                setSubscriptionsData(subs);
              }
            });
          setPersistence(auth, browserLocalPersistence)
            .then(() => {
              return signInWithEmailAndPassword(auth, user.email, user.password);
            })
            .catch((error) => {
            });
        } else {
          setCurrentUser(null);
        }
      } catch (error) {

      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe(); // eslint-disable-next-line
  }, []);

  const value = {
    loading,
    setLoading,
    linkEmailProvider,
    signInWithGoogle,
    linkGoogleProvider,
    signInWithFacebook,
    linkFacebookProvider,
    signInWithMicrosoft,
    linkMicrosoftProvider,
    updateAccountEmail,
    updateAccountPassword,
    unlinkProvider,
    deleteAccount,
    bottomNav,
    setBottomNav,
    currentUser,
    register,
    getUser,
    updateUser,
    userData,
    setUserData,
    products,
    articles,
    subscriptionsData,
    checkSubcollection,
    getSubColDocs,
    handleUser,
    login,
    customClaimRole,
    setCustomClaimRole,
    getCustomClaimRole,
    logout,
    resetPassword,
    CheckoutSession
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'

function Terms() {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    } // eslint-disable-next-line
  }, []);

  if (loading === true) {
    return <LoaderScreen />
  }

  return (
    <Container maxWidth="lg">
      <Box mt={5}>
        <Typography mb={5} variant="h4">Terms of Service and Privacy Policy</Typography>
        <Typography mb={3} variant="h5">Terms of Service</Typography>
        <Typography sx={{ fontSize: '85%' }}>
          All users of CheckPay must be financial at all times when accessing or using the service (including validly granted free access, where applicable) and agree to the terms and conditions of the user's agreement with CheckPay, including that the user:
          <Box display="inline"><Typography ml={-2} sx={{ fontSize: '85%' }}>
            <ol>
              <br />
              <li>has paid (or been gifted) the right to access CheckPay for their own personal use for a period of one week (which may or may not offer recurring automatic billing for convenience) and agree not to use any of CheckPay's information, tools, data or other content for professional or commercial purposes, nor to allow any other individual to use CheckPay for any purposes. Any such use is deemed unauthorised and contrary to these terms and conditions of service.</li>
              <br />
              <li>is responsible for the information they input and provide to CheckPay and the accuracy of the data they enter, as well as all calculations dependant on the information they provide.</li>
              <br />
              <li>has requested to use CheckPay's blog and informational resources, as well as online tools to obtain a preliminary analysis of a limited set of pay rules likely to apply to their employment, based on information they have provided.</li>
              <br />
              <li>acknowledges that the report does <b>not</b> calculate their pay, does <b>not</b> constitute legal advice and must <b>not</b> be used without further review and/or advice. CheckPay does <b>not</b> provide legal advice. CheckPay provides informational articles and a limited calculation algorithm to assess whether there is a risk of underpayment.</li>
              <br />
              <li>acknowledges and agrees that CheckPay provides estimate calculations and draft documents to identify any issue they may wish to raise their employer, legal practitioner or industrial representative. It is not a conclusive determination of any matter relating to wage compliance.</li>
              <br />
              <li>acknowledges and agrees that they will not use the calculations or draft documents produced by CheckPay as the basis of any complaint, dispute, allegation, claim or litigation without first attempting to resolve any concerns with their employer directly, or otherwise obtaining advice from a qualified legal practitioner or industrial representative.</li>
              <br />
              <li>as principal, appoints WisCa Pty Ltd t/as CheckPay as their paid agent on each occasion they generate a calculation or draft document, have agreed to the terms of use and hold a financial CheckPay subscription. The scope of appointment as paid agent is to prepare intial calculations or <b>draft</b> documents. The appointment as paid agent concludes on each occasion CheckPay finishes producing a calculation or draft document.</li>
              <br />
              <li>understands that the Australian Consumer Law creates consumer guarantees which cannot be excluded or modified. WisCa Pty Ltd t/as CheckPay is not required to provide a refund or replacement if I change my mind. However, WisCa Pty Ltd t/as CheckPay may choose provide a refund as a remedy in circumstances where the Australian Consumer Law does not require this. I understand that any such refund will be limited to a single one-week subscription fee for the period in which I first notified the issue.</li>
              <br />
              <li>does not intend to use CheckPay for any other purpose than those stated in these terms and conditions and will not use any of the information, tools, data or other intellectual property available to them for any other purpose.</li>
              <br />
              <li>agrees that, other than under the Australian Consumer Law, WisCa Pty Ltd t/as CheckPay's total aggregate liability to them for loss or damage arising under this agreement or in connection with access to CheckPay and the use of any information, tools or resources provided by CheckPay, whether under contract, tort (including negligence), under statute or otherwise act law or in equity, shall be limited to the value of the one-week subscription for the period in which the loss or damage occurred.</li>
              <br />
              <li>acknowledges and agrees that WisCa Pty Ltd t/as CheckPay retains ownership of all intellectual property including copyrights, patents, trademarks and other legal and moral rights in connection with CheckPay and checkpay.com.au.</li>
              <br />
              <li>consents to WisCa Pty Ltd t/as CheckPay storing, using and disclosing the personal information they provide in accordance with CheckPay's Privacy Policy. The User consents to the use and/or disclosure of personal information when using CheckPay features.</li>
              <br />
              <li>acknowledges that WisCa Pty Ltd t/as CheckPay is a private company and is in no way affiliated with the Australian Government, the Fair Work Commission, the Fair Work Ombudsman or any other regulatory body or tribunal.</li>
              <br />
            </ol>
          </Typography>
          </Box>
        </Typography>
        <Typography mb={3} sx={{ fontSize: '85%' }}>
          <Box display="inline"><Typography mb={3} variant="h5">Privacy Policy</Typography></Box>
          WisCa Pty Ltd trading as Check Pay is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information.
          <br />
          <br />
          We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.
          <br />
          <br />
          A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner at www.aoic.gov.au
          <br />
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">What is Personal Information and why do we collect it?</Typography></Box>
          Personal Information is information or an opinion that identifies an individual. Examples of Personal Information we collect include: names, addresses, email addresses, phone and facsimile numbers.
          <br />
          <br />
          This Personal Information is obtained in many ways including [interviews, correspondence, by telephone and facsimile, by email, via our website www.yourbusinessname.com.au, from your website, from media and publications, from other publicly available sources, from cookies- delete all that aren’t applicable] and from third parties. We don’t guarantee website links or policy of authorised third parties.
          <br />
          <br />
          We collect your Personal Information for the primary purpose of providing our services to you, providing information to our clients and marketing. We may also use your Personal Information for secondary purposes closely related to the primary purpose, in circumstances where you would reasonably expect such use or disclosure. You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing.
          <br />
          <br />
          When we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting the information and how we plan to use it.
          <br />
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Sensitive Information</Typography></Box>
          Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual's racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information.
          <br />
          <br />
          Sensitive information will be used by us only:
          <br />
          <br />
          <li>for the primary purpose for which it was obtained</li>
          <br />
          <li>for a secondary purpose that is directly related to the primary purpose</li>
          <br />
          <li>with your consent, or where required or authorised by law.</li>
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Third Parties</Typography></Box>
          Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party.
          <br />
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Disclosure of Personal Information</Typography></Box>
          Your Personal Information may be disclosed in a number of circumstances including the following:
          <br />
          <br />
          <li>third parties where you consent to the use or disclosure; and</li>
          <br />
          <li>where required or authorised by law.</li>
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Security of Personal Information</Typography></Box>
          Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorised access, modification or disclosure.
          <br />
          <br />
          When your Personal Information is no longer needed for the purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your Personal Information. However, most of the Personal Information is or will be stored in client files which will be kept by us for a minimum of 7 years.
          <br />
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Access to your Personal Information</Typography></Box>
          You may access the Personal Information we hold about you and to update and/or correct it, subject to certain exceptions. If you wish to access your Personal Information, please contact us in writing.
          <br />
          <br />
          WisCa Pty Ltd trading as Check Pay will not charge any fee for your access request, but may charge an administrative fee for providing a copy of your Personal Information.
          <br />
          <br />
          In order to protect your Personal Information we may require identification from you before releasing the requested information.
          <br />
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Maintaining the Quality of Your Personal Information</Typography></Box>
          It is an important to us that your Personal Information is up to date. We  will  take reasonable steps to make sure that your Personal Information is accurate, complete and up-to-date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you.
          <br />
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Policy Updates</Typography></Box>
          This Policy may change from time to time and is available on our website.
          <br />
          <br />
          <Box display="inline"><Typography mb={3} variant="h6">Privacy Policy Complaints and Enquiries</Typography></Box>
          If you have any queries or complaints about our Privacy Policy please contact us at:
          <br />
          <br />
          hello@checkpay.com.au
        </Typography>
        <Button color="inherit" variant="outlined" component={Link} to={"/"}>CheckPay Home</Button>
      </Box>
    </Container>
  );
}

export default Terms
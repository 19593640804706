import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LoaderScreen from '../components/LoaderScreen'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { useAuth } from '../contexts/AuthContext'

function Administrator() {

  const { currentUser } = useAuth()
  const [loading, setLoading] = useState(true)
  const [isSwitchOn, setIsSwitchOn] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const onSwitchAction = () => {
    if (isSwitchOn) {
      setIsSwitchOn(false);
    } else {
      setIsSwitchOn(true);
    }
    if (isEditing) {
      setIsEditing(false);
      setDisabled(true);
    } else {
      setIsEditing(true);
      setDisabled(false);
      console.log(disabled)
    }
  }

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    }
  }, []);

  if (loading === true) {

    return <LoaderScreen />

  } else {

    return (
      <>
        <Box mt={5} mb={10}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="h4" mb={5}>Administration</Typography>
            <Switch
              onChange={onSwitchAction}
              checked={isSwitchOn}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Grid container mb={3} spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography mt={-2} mb={-3} variant="body1" align="left" fontSize="medium" sx={{ color: '#C0C0C0' }}>Logged in as Administrator: <b>{currentUser && currentUser.uid }</b>.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }
}

export default Administrator
import React from 'react'
import MyGigCoGif from '../images/MyGigCoGif.gif'
import Box from '@mui/material/Box'

export default function LoaderScreen () {
  return (
    <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <img src={MyGigCoGif} alt="thinking..." height="100vh" />
    </Box>
  );
}
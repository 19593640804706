import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LoaderScreen from '../components/LoaderScreen'
import GoogleMaps from '../components/GoogleMaps'
import enLocale from 'date-fns/locale/en-AU'
import { formatISO, format, parseISO, startOfDay } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import EmailIcon from '../images/EmailButton.png'
import GoogleIcon from '../images/GoogleButton.png'
import FacebookIcon from '../images/FacebookButton.png'
import MicrosoftIcon from '../images/MicrosoftButton.png'
import { useAuth } from '../contexts/AuthContext'
import { functions } from '../firebase'
import { httpsCallable } from 'firebase/functions'

function Profile() {

  const { currentUser, customClaimRole, setCustomClaimRole, logout, userData, setUserData, updateUser, linkGoogleProvider, linkFacebookProvider, linkMicrosoftProvider, updateAccountEmail, updateAccountPassword, unlinkProvider, deleteAccount, subscriptionsData } = useAuth()
  const [loading, setLoading] = useState(true)
  const [authError, setAuthError] = useState("")
  const [changeCredError, setChangeCredError] = useState("")
  const [deleteError, setDeleteError] = useState("")
  const [authMessage, setAuthMessage] = useState("")
  const [changeCredMessage, setChangeCredMessage] = useState("")
  const [deleteMessage, setDeleteMessage] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()
  const [isSwitchOn, setIsSwitchOn] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const onSwitchAction = () => {
    if (isSwitchOn) {
      setIsSwitchOn(false);
    } else {
      setIsSwitchOn(true);
    }
    if (isEditing) {
      updateUser(currentUser.uid, userData)
      setIsEditing(false);
      setDisabled(true);
    } else {
      setIsEditing(true);
      setDisabled(false);
    }
  }
  const [inputValue, setInputValue] = useState("")
  const [values, setValues] = useState([])
  const subEnd = subscriptionsData.length > 0 ? format(new Date(Math.max(...subscriptionsData.map(s => s.current_period_end.toDate()))), "dd/MM/yyyy") : null

  async function handleLinkGoogle() {
    setLoading(true)
    setAuthError("")
    setAuthMessage("")
    await linkGoogleProvider()
      .then(user => {
        setLoading(false)
        setAuthMessage("You can now sign in with Google.")
        return user
      })
      .catch((err) => {
        setLoading(false)
        setAuthError(err.message)
        /* switch (err.code) {
          case "auth/Invalid-email":
            setError('You entered an invalid email address.')
            break
          case "auth/user-disabled":
            setError('CheckPay has temporarily disabled the account registered at this email.')
            break
          case "auth/user-not-found":
            setError('The email may not be correct or may not be registered for CheckPay.');
            break;
          case "auth/wrong-password":
            setError('The password you entered was incorrect.');
            break;
          default:
        } */
      })
  }

  async function handleLinkFacebook() {
    setLoading(true)
    setAuthError("")
    setAuthMessage("")
    await linkFacebookProvider()
      .then(user => {
        setLoading(false)
        setAuthMessage("You can now sign in with Facebook.")
        return user
      })
      .catch((err) => {
        /*         const errorCode = err.code;
                const errorMessage = err.message;
                // The email of the user's account used.
                const email = err.customData.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(err); */
        setLoading(false)
        setAuthError(err.message)
      })
  }

  async function handleLinkMicrosoft() {
    setLoading(true)
    setAuthError("")
    setAuthMessage("")
    await linkMicrosoftProvider()
      .then(user => {
        setLoading(false)
        setAuthMessage("You can now sign in with Microsoft.")
        return user
      })
      .catch((err) => {
        /*         const errorCode = err.code;
                const errorMessage = err.message;
                // The email of the user's account used.
                const email = err.customData.email;
                // The AuthCredential type that was used.
                const credential = MicrosoftAuthProvider.credentialFromError(err); */
        setLoading(false)
        setAuthError(err.message)
      })
  }

  function handleUpdates() {
    if (email) {
      updateAccountEmail(email)
    }
    if (password) {
      updateAccountPassword(password)
    }
  }

  async function unlinkAuthProvider(pid) {
    setLoading(true)
    setAuthError("")
    setAuthMessage("")

    await unlinkProvider(pid)
      .then(user => {
        setLoading(false)
        return user
      })
      .catch((err) => {
        setLoading(false)
        setAuthError(err.message)
      })
  }

  async function handleDeleteAccount() {

    setLoading(true)
    setDeleteError("")

    await deleteAccount()
      .then(user => {
        handleSubmitLogout()
        return user
      })
      .catch((err) => {
        setLoading(false)
        setDeleteError(err.message)
      })
  }

  async function handleChangeEmailPassword(e) {
    e.preventDefault();
    await updateAccountEmail(email);
    await updateAccountPassword(password);
  }

  async function handleSubmitLogout() {
    try {
      await logout();
      setCustomClaimRole("")
      navigate("/");
      localStorage.removeItem('activeTab')
      setLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  };

  function handleChange(e) {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setUserData({
      ...userData,
      [fieldName]: fieldValue,
    });
  }

  async function RedirectCustomerPortal() {
    setLoading(true)

    const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

    const { data } = await functionRef({
      returnUrl: window.location.origin
    });

    window.location.assign(data.url);
  }

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)
    console.log(JSON.stringify(currentUser))

    async function getBusinessNames() {
      let options = []
      try {
        const response = await axios.post(`https://abr.business.gov.au/json/MatchingNames.aspx?name=${inputValue}&maxResults=10&callback=callback&guid=${process.env.REACT_APP_ABN_KEY}`)
        const namesdata = JSON.parse(response.data.replace('callback(', '').replace('})', '}')).Names
        const activenames = namesdata.filter(busname => busname.AbnStatus !== "0000000002")
        activenames.forEach((e, i) => {
          options.push(e)
        })
        setValues(options)
      } catch (e) {
        console.log(e)
      }
    }

    getBusinessNames()

    return () => {
      cancel = true;
    } // eslint-disable-next-line
  }, [inputValue]);

  if (loading === true) {

    return <LoaderScreen />

  } else {

    return (
      <>
        <Box mt={5}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="h4" mb={5}>Profile</Typography>
            <Switch
              onChange={onSwitchAction}
              checked={isSwitchOn}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
          <Grid container mb={3} spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={4}>
              <TextField error={!userData.firstName ? true : false} autoFocus fullWidth mb={3} id="firstName" label="First Name" name="firstName" value={userData.firstName} variant="outlined" onChange={handleChange} disabled={disabled} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField error={!userData.lastName ? true : false} fullWidth mb={3} id="lastName" label="Last Name" name="lastName" value={userData.lastName} variant="outlined" onChange={handleChange} disabled={disabled} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
                <DatePicker
                  dateAdapter={AdapterDateFns}
                  label="Date of Birth"
                  inputFormat="dd/MM/yyyy"
                  value={parseISO(userData.dob) || ""}
                  onChange={(newValue) => {
                    setUserData({
                      ...userData,
                      "dob": formatISO(startOfDay(newValue))
                    });
                  }}
                  renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} error={!userData.dob ? true : false} fullWidth variant="outlined" disabled={disabled} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <GoogleMaps error={!userData.gigAddress ? true : false} name="gigAddress" value={userData.gigAddress} label="Your Address" disabled={disabled} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                mb={5}
                fullWidth
                freeSolo
                includeInputInList
                value={userData.gigName}
                onChange={(event, newValue) => {
                  setUserData({
                    ...userData,
                    "gigName": newValue,
                  });
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="gigName"
                options={values.map((n, i) => n.Name)}
                renderInput={(params) => <TextField {...params} error={!userData.employerName ? true : false} label="Your Gig's Name" variant="outlined" disabled={disabled} />}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} disabled={disabled}>
              <Typography mt={5} variant="h6">Link Social to Login</Typography>
              <Box ml={-1} mt={2} mr={2} mb={2} display="flex" justifyContent="left" alignItems="left">
                {currentUser && currentUser.providerData.some(e => e.providerId === "password") ? (
                  <Tooltip title="Click to unlink email" placement="top">
                    <Button onClick={() => { unlinkAuthProvider("password") }}><img src={EmailIcon} alt="" style={{ height: '40px' }} /></Button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Click to link and login with email" placement="top">
                    <Button component={Link} to={"/linkemail"}><img src={EmailIcon} alt="" style={{ height: '40px', opacity: '0.3' }} /></Button>
                  </Tooltip>
                )}
                <Tooltip title={currentUser && currentUser.providerData.some(e => e.providerId === "google.com") ? "Click to unlink Google" : "Click to link and login with Google"} placement="top">
                  <Button onClick={() => { currentUser && currentUser.providerData.some(e => e.providerId === "google.com") ? unlinkAuthProvider("google.com") : handleLinkGoogle() }}>{currentUser && currentUser.providerData.some(e => e.providerId === "google.com") ? <img src={GoogleIcon} alt="" style={{ height: '40px' }} /> : <img src={GoogleIcon} alt="" style={{ height: '40px', opacity: '0.3' }} />}</Button>
                </Tooltip>
                <Tooltip title={currentUser && currentUser.providerData.some(e => e.providerId === "facebook.com") ? "Click to unlink Facebook" : "Click to link and login with Facebook"} placement="top">
                  <Button onClick={() => { currentUser && currentUser.providerData.some(e => e.providerId === "facebook.com") ? unlinkAuthProvider("facebook.com") : handleLinkFacebook() }}>{currentUser && currentUser.providerData.some(e => e.providerId === "facebook.com") ? <img src={FacebookIcon} alt="" style={{ height: '40px' }} /> : <img src={FacebookIcon} alt="" style={{ height: '40px', opacity: '0.3' }} />}</Button>
                </Tooltip>
                <Tooltip title={currentUser && currentUser.providerData.some(e => e.providerId === "microsoft.com") ? "Click to unlink Microsoft" : "Click to link and login with Microsoft"} placement="top">
                  <Button onClick={() => { currentUser && currentUser.providerData.some(e => e.providerId === "microsoft.com") ? unlinkAuthProvider("microsoft.com") : handleLinkMicrosoft() }}>{currentUser && currentUser.providerData.some(e => e.providerId === "microsoft.com") ? <img src={MicrosoftIcon} alt="" style={{ height: '40px' }} /> : <img src={MicrosoftIcon} alt="" style={{ height: '40px', opacity: '0.3' }} />}</Button>
                </Tooltip>

              </Box>
              <Box mt={2}>
                {authError && (
                  <Typography mt={2} sx={{ color: '#BE0000' }} variant="body1">
                    {authError}
                  </Typography>
                )}
                {authMessage && (
                  <Typography mt={2} sx={{ color: '#00BE00' }} variant="body1">
                    {authMessage}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography mt={5} mb={2} variant="h6">Update Email or Password</Typography>
              <Box component="form" onSubmit={handleUpdates} noValidate mt={2}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="new email address"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputLabelProps={{ ...{ shrink: true } }}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="new password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputLabelProps={{ ...{ shrink: true } }}
                />
                <Box
                  mt={3}
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    sx={{ mb: 0 }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
                {changeCredError && (
                  <Typography mt={2} sx={{ color: '#BE0000' }} variant="body1">
                    {changeCredError}
                  </Typography>
                )}
                {changeCredMessage && (
                  <Typography mt={2} sx={{ color: '#00BE00' }} variant="body1">
                    {changeCredMessage}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography mt={5} mb={2} variant="h6">MyGigCo Subscription</Typography>
              <Button display="inline" align="right" sx={{ height: '50px', fontSize: '90%' }} variant="outlined" onClick={() => RedirectCustomerPortal()}>Manage MyGigCo Subscription</Button>
              <Typography mt={2} variant="body1" align="left" fontSize="medium" sx={{ color: '#C0C0C0' }}>{subEnd && customClaimRole.charAt(0).toUpperCase() + customClaimRole.slice(1)} access to {subEnd}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography mt={5} mb={2} variant="h6">Delete MyGigCo Account</Typography>
              <Button display="inline" align="right" sx={{ height: '50px', fontSize: '90%' }} variant="outlined" onClick={() => { if (window.confirm('Are you sure you wish to delete your MyGigCo Account? All data will be lost and your subscription will end immediately. You will not be able to restore your data and will not receive a refund. You will be responsible for the gig company created by MyGigCo.')) handleDeleteAccount() }}>Delete MyGigCo Account</Button>
              <Typography mt={2} variant="body1" align="left" fontSize="medium" sx={{ color: '#BE0000' }}>Warning: You cannot undo this action.</Typography>
            </Grid>
          </Grid>
          <br />
          <br />
        </Box>
      </>
    );
  }
}

export default Profile
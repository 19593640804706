import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { getStripePayments, createCheckoutSession, onCurrentUserSubscriptionUpdate } from '@stripe/firestore-stripe-payments'

const firebaseConfig = {
  apiKey: "AIzaSyAc-xzQRMzhXYNqUqfa_3_imRbpub5_v0o",
  authDomain: "mygigco.firebaseapp.com",
  projectId: "mygigco",
  storageBucket: "mygigco.appspot.com",
  messagingSenderId: "426145299644",
  appId: "1:426145299644:web:d81bb5757b6b5f814764b9",
  measurementId: "G-YMMLZKNHQF"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6Le88vojAAAAAHBk4xCYRmi4Epji1ih5W1qKcOAC'),
  isTokenAutoRefreshEnabled: true
});

getToken(appCheck)
  .then(() => {
    console.log('AppCheck token retrieved successfully.')
  })
  .catch((error) => {
    console.log(error.message)
  })

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, "australia-southeast1");
const payments = getStripePayments(firebaseApp, {
  productsCollection: "products",
  customersCollection: "users",
});

export { auth, db, storage, functions, payments, analytics, createCheckoutSession, onCurrentUserSubscriptionUpdate };
import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'
import { useAuth } from '../contexts/AuthContext'


function LinkEmail() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { currentUser, customClaimRole, linkEmailProvider } = useAuth()

  async function handleLinkEmail() {
    setLoading(true)
    setError("")
    setMessage("")

    await linkEmailProvider(email, password)
      .then((usercred) => {
        const user = usercred.user;
        setMessage("You can now log in using " + email)
        setLoading(false)
        navigate("/profile")
        return user
      }).catch((err) => {
        setError(err.message)
        setLoading(false)
        navigate("/profile")
      });
  }

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    }
  }, [currentUser, customClaimRole]);

  if (loading === true) {

    return <LoaderScreen />

  } else {

    return (
      <>
        <Container maxWidth="sm">
          <Box component="form" onSubmit={handleLinkEmail} noValidate mt={20}>
            <Typography variant="h4">Link Email</Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="email address"
              type="email"
              value={email}
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ ...{ shrink: true } }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="password"
              type="password"
              value={password}
              autoComplete="password"
              autoFocus
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ ...{ shrink: true } }}
            />
            <Box
              mt={3}
              component="span"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                  variant="contained"
                  sx={{ mb: 0 }}
                  type="submit"
                >
                  Link Email to CheckPay Account
                </Button>
            </Box>
            <Box
              mt={5}
              component="span"
              display="flex"
              justifyContent="right"
              alignItems="right"
            >
              <Button color="inherit" variant="outlined" component={Link} to={"/profile"}>Cancel</Button>
            </Box>
            {error && (
              <Typography mt={2} sx={{ color: '#BE0000' }} variant="body1">
                {error}
              </Typography>
            )}
            {message && (
              <Typography mt={2} sx={{ color: '#00BE00' }} variant="body1">
                {message}
              </Typography>
            )}
          </Box>
        </Container>
      </>
    )
  }
}

export default LinkEmail
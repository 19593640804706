import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'
import GoogleIcon from '../images/GoogleButton.png'
import FacebookIcon from '../images/FacebookButton.png'
import MicrosoftIcon from '../images/MicrosoftButton.png'
import { useAuth } from '../contexts/AuthContext'

function Auth() {

  let [authMode, setAuthMode] = useState("login")

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { currentUser, customClaimRole, signInWithGoogle, signInWithFacebook, signInWithMicrosoft, login, register, resetPassword } = useAuth()

  const changeAuthMode = (option) => {
    const mode = option
    setLoading(true)
    setEmail("")
    setPassword("")
    setConfirmPassword("")
    setError("")
    setMessage("")
    setAuthMode(mode)
    setLoading(false)
  }

  function validateEmail() {
    let isValidEmail = true
    if (/\S+@\S+\.\S+/.test(email) !== true) {
      isValidEmail = false
      setError('You did not enter a valid email address.')
    }
    return isValidEmail
  }

  function validatePassword() {
    let isValidPassword = true
    if (password !== '' && confirmPassword !== '') {
      if (password !== confirmPassword) {
        isValidPassword = false
        setError('The passwords you entered do not match.')
      }
    }
    return isValidPassword
  }

  async function handleSubmitRegister(e) {
    e.preventDefault();

    setError("")
    setMessage("")
    if (validateEmail()) {
      if (validatePassword()) {
        setLoading(true)
        register(email, password)
          .then(user => {
            if (user) {
              navigate("/")
              setLoading(false)
            } else {
              setLoading(false)
            }
          })
          .catch((err) => {
            setLoading(false)
            switch (err.code) {
              case "auth/email-already-in-use":
                setError('This email is already registered for MyGigCo.')
                break
              case "auth/invalid-email":
                setError('You entered an invalid email address.')
                break;
              case "auth/weak-password":
                setError('The password you have chosen is too weak.')
                break;
              default:
            }
          })
      }
    }
  }

  async function handleSubmitLogin(e) {
    setLoading(true)
    e.preventDefault();

    setError("")
    setMessage("")

    await login(email, password)
      .then(user => {
        if (user) {
          if (customClaimRole === "user") {
            navigate("/user")
          } else if (customClaimRole === "gig") {
              navigate("/profile")
          } else if (customClaimRole === "admin") {
            navigate("/admin")
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        switch (err.code) {
          case "auth/Invalid-email":
            setError('You entered an invalid email address.')
            break
          case "auth/user-disabled":
            setError('MyGigCo has temporarily disabled the account registered at this email.')
            break
          case "auth/user-not-found":
            setError('The email may not be correct or may not be registered for MyGigCo.');
            break;
          case "auth/wrong-password":
            setError('The password you entered was incorrect.');
            break;
          default:
        }
      })
  }

  async function handleGoogleAuth() {
    setLoading(true)

    setError("")
    setMessage("")

    await signInWithGoogle()
      .then(user => {
        if (user) {
          if (customClaimRole === "user") {
            navigate("/user")
          } else if (customClaimRole === "gig") {
              navigate("/profile")
          } else if (customClaimRole === "admin") {
            navigate("/admin")
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        setError(err.message)
      })
  }

  async function handleFacebookAuth() {
    setLoading(true)

    setError("")
    setMessage("")

    await signInWithFacebook()
      .then(user => {
        if (user) {
          if (customClaimRole === "user") {
            navigate("/user")
          } else if (customClaimRole === "gig") {
              navigate("/profile")
          } else if (customClaimRole === "admin") {
            navigate("/admin")
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.code === 'auth/account-exists-with-different-credential') {
          setError("The email connected to this Facebook account is already in use.")
        }
      })
  }

  async function handleMicrosoftAuth() {
    setLoading(true)

    setError("")
    setMessage("")

    await signInWithMicrosoft()
      .then(user => {
        if (user) {
          if (customClaimRole === "user") {
            navigate("/user")
          } else if (customClaimRole === "gig") {
              navigate("/profile")
          } else if (customClaimRole === "admin") {
            navigate("/admin")
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err.code === 'auth/account-exists-with-different-credential') {
          setError("The email connected to this Microsoft account is already in use.")
        }
      })
  }

  function handleSubmitReset() {
    resetPassword(email);
    setMessage("MyGigCo has sent you an email with the next steps.");
  };

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    }
  }, [currentUser, customClaimRole]);

  if (loading === true) {

    return <LoaderScreen />

  } else {

    if (authMode === "login") {

      return (
        <>
          <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmitLogin} noValidate mt={20}>
              <Typography variant="h4">Login</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="email address"
                type="email"
                value={email}
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{ ...{ shrink: true } }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="password"
                type="password"
                value={password}
                autoComplete="password"
                autoFocus
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{ ...{ shrink: true } }}
              />
              <Box
                mt={3}
                component="span"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  sx={{ mb: 0 }}
                  type="submit"
                >
                  Login
                </Button>
                <Button
                  variant="text"
                  sx={{ fontFamily: 'Geomanist Light', backgroundColor: '#ffffff', color: '#000000', mb: 0 }}
                  onClick={() => changeAuthMode("register")}
                >
                  Register
                </Button>
                <Button
                  variant="text"
                  sx={{ fontFamily: 'Geomanist Light', backgroundColor: '#ffffff', color: '#000000', mb: 0 }}
                  onClick={() => changeAuthMode("reset")}
                >
                  Reset Password
                </Button>
              </Box>
              <Box mt={5} justifyContent="left" alignItems="left">
                <Typography mt={1} mb={3} variant="body2">or sign in with</Typography>
              </Box>
              <Box ml={-1} component="span" display="flex" justifyContent="left" alignItems="left">
                <Button onClick={handleGoogleAuth}><img src={GoogleIcon} alt="" style={{ height: '42px' }} /></Button>
                <Button onClick={handleFacebookAuth}><img src={FacebookIcon} alt="" style={{ height: '42px' }} /></Button>
                <Button onClick={handleMicrosoftAuth}><img src={MicrosoftIcon} alt="" style={{ height: '42px' }} /></Button>
              </Box>
              {error && (
                <Typography mt={2} sx={{ color: '#BE0000' }} variant="body1">
                  {error}
                </Typography>
              )}
              {message && (
                <Typography mt={2} sx={{ color: '#00BE00' }} variant="body1">
                  {message}
                </Typography>
              )}
            </Box>
            <Box mt={5} justifyContent="left" alignItems="left">
              <Typography mt={1} variant="body2">WisCa Pty Ltd trading as <b>MyGigCo</b></Typography>
              <Typography mb={1} variant="body2">ACN 625 587 859</Typography>
              <Typography mt={2} mb={3} variant="body2">hello@mygigco.com.au</Typography>
            </Box>
          </Container>
        </>
      )
    }

    if (authMode === "register") {

      return (
        <>
          <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmitRegister} noValidate mt={20}>
              <Typography variant="h4">Register</Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="email address"
                type="email"
                value={email}
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{ ...{ shrink: true } }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="password"
                type="password"
                value={password}
                autoComplete="password"
                autoFocus
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="password"
                type="password"
                value={confirmPassword}
                autoComplete="password"
                autoFocus
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputLabelProps={{ ...{ shrink: true } }}
              />
              <Box
                mt={3}
                component="span"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  sx={{ mb: 0 }}
                  type="submit"
                >
                  Register
                </Button>
                <Button
                  variant="text"
                  sx={{ fontFamily: 'Geomanist Light', backgroundColor: '#ffffff', color: '#000000', mb: 0 }}
                  onClick={() => changeAuthMode("login")}
                >
                  Login
                </Button>
              </Box>
              <br />
              <Typography mt={2} sx={{ color: '#ff0000' }} variant="body1">
                {error}
              </Typography>
            </Box>
          </Container>
        </>
      )
    }
  }

  return (
    <>
      <Container maxWidth="sm">
        <Box mt={20}>
          <Typography variant="h4">Reset Password</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="email address"
            type="email"
            value={email}
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <Typography mt={2} sx={{ color: '#ff0000' }} variant="body1">
            {error}
          </Typography>
          <Box
            mt={3}
            component="span"
            display="flex"
            justifyContent="space-between"
            alignItems="left"
          >
            <Button
              variant="contained"
              sx={{ mb: 0 }}
              type="submit"
              onClick={handleSubmitReset}
            >
              Reset Password
            </Button>
            <Button
              variant="text"
              sx={{ fontFamily: 'Geomanist Light', backgroundColor: '#ffffff', color: '#000000', mb: 0 }}
              onClick={() => changeAuthMode("login")}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default Auth
import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import LogInIcon from '../images/LogInIcon.png'
import MyGigCoTopLogo from '../images/MyGigCoTopLogo.png'
import LogOutIcon from '../images/LogOutIcon.png'
import { useAuth } from '../contexts/AuthContext'

export default function TopBar() {

  const { currentUser, logout, setCustomClaimRole } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  async function handleSubmitLogout() {
    try {
      await logout();
      setCustomClaimRole("")
      navigate("/");
      localStorage.removeItem('activeTab')
      setLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 2000)

    return () => {
      cancel = true;
    } // eslint-disable-next-line
  }, []);

  if (loading === true) {

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>

            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    )

  } else {

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Container align="center" maxWidth="xl">
            <Toolbar disableGutters>
              <img src={MyGigCoTopLogo} alt="$" height="50" width="auto"/>
              <Typography
                variant="h4"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'flex', flexGrow: 1 },
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
              </Typography>
              {currentUser ? (
                <Button color="inherit" onClick={handleSubmitLogout}><img src={LogOutIcon} alt="" height="35vh" /></Button>
              ) : (
                <Button color="inherit" component={Link} to={"/auth"}><img src={LogInIcon} alt="" height="35vh" /></Button>
              )
              }
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    );
  }
}
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'

function NotFound() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    }
  }, []);

  if (loading === true) {

    return <LoaderScreen />

  } else {
    return (
      <>
        <Box mt={20}>
          <Typography variant="h4" mb={1}>404  |  Not Found</Typography>
          <br />
          <Button variant="outlined" href="/">Home</Button>
        </Box>
      </>
    );
  }
}

export default NotFound